html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif !important;
  background: #f6f3f7;
}

.App {
  position: relative;
}

.body-overflow {
  overflow: hidden;
}
svg {
  path {
    transition: 0.4s;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input {
  &:focus {
    &::placeholder {
      color: transparent !important;
    }
  }
}
* {
  font-family: "Noto Sans", sans-serif !important;
}

ul {
  padding-left: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
.container {
  padding: 0 1.6rem;
  max-width: 126.4rem;
  margin: 0 auto;
}
svg {
  transition: 0.4s;
}
.container-second {
  padding: 0 1.6rem;
  max-width: 130.8rem;
  margin: 0 auto;
}

@media screen and (max-width: 1199.9px) {
  html {
    font-size: 9px;
  }
}

@media screen and (max-width: 1023.9px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 991.9px) {
  html {
    font-size: 7px;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 1.6rem;
}

@media screen and (min-width: 1199.9px) {
  .xl-1 {
    grid-column: span 1;
  }

  .xl-2 {
    grid-column: span 2;
  }

  .xl-3 {
    grid-column: span 3;
  }

  .xl-4 {
    grid-column: span 4;
  }

  .xl-5 {
    grid-column: span 5;
  }

  .xl-6 {
    grid-column: span 6;
  }

  .xl-7 {
    grid-column: span 7;
  }

  .xl-8 {
    grid-column: span 8;
  }

  .xl-9 {
    grid-column: span 9;
  }

  .xl-10 {
    grid-column: span 10;
  }

  .xl-12 {
    grid-column: span 12;
  }
}

@media screen and (max-width: 1199.9px) and (min-width: 991.9px) {
  .lg-0 {
    display: none;
  }

  .lg-1 {
    grid-column: span 1;
  }

  .lg-2 {
    grid-column: span 2;
  }

  .lg-3 {
    grid-column: span 3;
  }

  .lg-4 {
    grid-column: span 4;
  }

  .lg-5 {
    grid-column: span 5;
  }

  .lg-6 {
    grid-column: span 6;
  }

  .lg-7 {
    grid-column: span 7;
  }

  .lg-8 {
    grid-column: span 8;
  }

  .lg-9 {
    grid-column: span 9;
  }

  .lg-10 {
    grid-column: span 10;
  }

  .lg-12 {
    grid-column: span 12;
  }
}

@media screen and (max-width: 991.9px) and (min-width: 767.9px) {
  .md-0 {
    display: none;
  }

  .md-1 {
    grid-column: span 1;
  }

  .md-2 {
    grid-column: span 2;
  }

  .md-3 {
    grid-column: span 3;
  }

  .md-4 {
    grid-column: span 4;
  }

  .md-5 {
    grid-column: span 5;
  }

  .md-6 {
    grid-column: span 6;
  }

  .md-7 {
    grid-column: span 7;
  }

  .md-8 {
    grid-column: span 8;
  }

  .md-9 {
    grid-column: span 9;
  }

  .md-10 {
    grid-column: span 10;
  }

  .md-12 {
    grid-column: span 12;
  }
}

@media (min-width: 767.9px) {
  .pagination_desk_none {
    padding-bottom: 2.4rem;

    .swiper-pagination {
      display: none !important;
    }
  }
}

@media screen and (max-width: 767.9px) {
  .sm-0 {
    display: none;
  }

  .row {
    grid-gap: 0 0.8rem;
  }

  .sm-3 {
    grid-column: span 3;
  }
  .sm-4 {
    grid-column: span 4;
  }

  .sm-6 {
    grid-column: span 6;
  }

  .sm-12 {
    grid-column: span 12;
  }

  html {
    font-size: 10px;
  }

  .container,
  .container-second {
    max-width: 100%;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .breadCrumb {
    display: none !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #121212;
}

input,
textarea,
select {
  color: #444;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  outline: 0;
  cursor: pointer;
}

button,
input,
textarea,
select {
  outline: 0;
}
button,
input,
a,
img {
  transition: 0.4s;
}
.loading {
  width: 100vw;
  height: 100vh;
  background: #430d4b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}
// .infinite-scroll-component__outerdiv {
//   width: 100%;
// }
// .infinite-scroll-component {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   width: 100%;
// }
.infiniteLoader {
  overflow: hidden;
  width: 100% !important;
  transform: translate(500%);
  padding: 1rem;
}
.loadingDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.singleVideo {
  width: calc(100% / 3 - 2.5rem / 3);
  h3 {
    width: 100% !important;
    margin-top: 1rem;
    text-align: center;
  }
}
.HeaderTop {
  .headerTopChild {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0 4rem;
    .headerTopLeft {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        li {
          margin-right: 3.2rem;
          list-style: none;
          &:last-child {
            display: flex;
            gap: 5px;
            align-items: center;
            svg {
              width: 20px;
              height: 20px;
            }
            a {
              font-weight: 800;
              color: #651471;
              opacity: 1;
            }
          }
          a {
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.9rem;
            letter-spacing: -0.0201em;
            color: #1d141f;
            opacity: 0.6;
            transition: 0.4s;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .headerTopRight {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
header {
  a.callNumber {
    font-style: normal;
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.0099em;
    color: #8e219e;
    border-bottom: 0.15rem solid #8e219e;
    padding-bottom: 0.4rem;
    width: fit-content;
    &:hover {
      opacity: 0.6;
    }
  }
  .socials {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 5.4rem;
    a {
      width: 3.4rem;
      height: 3.4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.1rem solid #b3a3b3;
      margin-right: 1rem;
      transition: 0.4s;
      svg {
        fill: #b2a3b2;
        width: 1.6rem;
        height: 1.6rem;
        path {
          transition: 0.4s;
        }
      }
      &:hover {
        border: 0.1rem solid #691475 !important;
        svg {
          path {
            fill: #691475 !important;
          }
        }
      }
    }
  }
  .lang {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 3.6rem;
    a {
      font-style: normal;
      font-weight: 800;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.0099em;
      color: #1d141f;
      opacity: 0.88;
      margin-left: 1.2rem;
      transition: 0.4s;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
.installmentSelect {
  margin-top: 2rem;
  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3.4rem;
    letter-spacing: -0.02em;
    color: #1d141f;
    opacity: 0.86;
    margin-bottom: 1.8rem;
    width: 100%;
  }
}
.installmentButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  label {
    position: relative;
    display: block;
    cursor: pointer;
    width: 47%;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 1.8rem;
    height: 1.8rem;
    opacity: 0;
  }
  input:checked ~ span {
    background-color: rgba(150, 30, 168, 0.073);
  }
  span {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3.4rem;
    letter-spacing: -0.02em;
    color: #1d141f;
    opacity: 0.86;
    margin-bottom: 0;
    border: 0.1rem solid #f6f3f7;
    background-color: #f6f3f7;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.8rem;
    border-radius: 0.8rem;
  }
}
.HeaderMiddle {
  padding-bottom: 2.4rem;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      margin-right: 2rem;
      img {
        height: 5.1rem;
        width: fit-content;
        object-fit: contain;
      }
    }
    .accountBar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        background: #ece7ed;
        border-radius: 0.8rem;
        height: 4.8rem;
        margin-left: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 800;
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: 0.0099em;
        color: #430d4b;
        padding: 0 1.6rem;
        white-space: nowrap;
        transition: 0.4s;
        svg {
          width: 2rem;
          margin-right: 1.2rem;
          height: 2rem;
        }
        &:hover {
          background-color: #430d4b;
          color: #ece7ed;

          svg {
            path {
              stroke: #ece7ed;
            }
          }
        }
        &:last-child {
          &:hover {
            svg {
              path {
                fill: #ece7ed;
                stroke: #430d4b;
              }
            }
          }
        }
      }
    }
  }
}

.orderNote {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
  strong {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2rem;
    letter-spacing: -0.0008em;
    color: #1d141f;
    display: block;
    margin-bottom: 2.4rem;
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2rem;
    letter-spacing: -0.0008em;
    color: #1d141f;
  }
}
.whatsApp {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 200;
  background-color: #55175e;
  padding: 1.5rem 2rem;
  border-radius: 50%;
  border: none;
  transition: 0.4s;
  &:hover {
    background-color: #430d4b;
    transition: 0.4s;
  }
}
.searchBar {
  border: 0.15rem solid #cabecd;
  border-radius: 0.8rem;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 0.4s;
  &:focus-within {
    border: 0.15rem solid #691475;
  }
  form {
    border-left: 0.15rem solid #cabecd;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2.8rem;
    height: 90%;

    button {
      background-color: transparent;
      padding: 0;
      border: none;
      margin-right: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    input {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: -0.0008em;
      color: #1d141f;
      transition: 0.4s;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      &::placeholder {
        opacity: 0.5;
      }
    }
  }
  .categoryBar {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    span {
      cursor: pointer;
      font-style: normal;
      font-weight: 800;
      font-size: 1.6rem;
      line-height: 2.2rem;
      letter-spacing: -0.0008em;
      color: #691475;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 2rem;
      svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-left: 1.2rem;
      }
    }
    .categoryParent {
      background: #ffffff;
      box-shadow: 0px 8px 20px rgba(196, 196, 196, 0.2);
      border-radius: 0.8rem;
      padding: 2rem 2.5rem;
      position: absolute;
      left: 0;
      top: 120%;
      z-index: 3;
      width: -webkit-fill-available;
      .categoryParentLink {
        font-style: normal;
        font-weight: 700;
        font-size: 1.2rem;
        color: #961ea8;
      }
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: -0.01em;
        color: #1d141f;
        opacity: 0.7;
        margin-bottom: 1.6rem;
        transition: 0.4s;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #961ea8;
          opacity: 1;
        }
      }
    }
  }
}
// .input-child {
//   position: relative !important;
//   .showPass {
//     position: relative;
//     top: 32px;
//     right: 10px;
//   }
// }
.input-pass {
  position: relative;
  span {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-5px, -50%);
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    svg {
      scale: 0.8;
      opacity: 0.6;
    }
  }
}
.HeaderBottom {
  background: #efeaf0;
  border-top: 0.15rem solid #dcd4dd;
  border-bottom: 0.15rem solid #dcd4dd;
  position: relative;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 7.2rem;
    li {
      transition: 0.4s;
      margin: 0 0.6rem;
      &:hover {
        svg {
          rotate: 0deg;
        }
      }
      a {
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.2rem;
        letter-spacing: -0.0008em;
        color: #1d141f;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: 0.4s;
        padding: 2.4rem 1rem;
        opacity: 0.7;
        white-space: nowrap;
        svg {
          width: 1.8rem;
          height: 1.8rem;
          margin-left: 1.2rem;
          rotate: 180deg;
        }
        &:hover {
          color: #430d4b;
          opacity: 1;
        }
      }
      .menuInner {
        position: absolute;
        left: 0;
        top: 99%;
        background: #ffffff;
        box-shadow: 0px 8px 20px rgba(196, 196, 196, 0.2);
        width: 100%;
        height: 70vh;
        z-index: 10;
        display: none;
        border-radius: 0 0 8px 8px;
        .menuinnerDetail {
          padding: 4rem 0;
        }
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 4.8rem;
          line-height: 6.5rem;
          color: #1d141f;
          border-bottom: 0.15rem solid #e6e3e6;
          padding-bottom: 0.3rem;
          margin-bottom: 4.8rem;

          svg {
            width: 1.2rem;
            height: 2rem;
            margin-left: 3.2rem;
          }
        }
        .menuInnerParent {
          display: flex;
          align-items: flex-start;
        }
        .menuLeft {
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          flex-wrap: wrap;
          // border-right: 0.15rem solid #e6e3e6;
          width: 100%;
          height: auto;
          max-height: 70vh;
          span,
          a {
            font-style: normal;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 2.6rem;
            color: #1d141f;
            display: block;
            margin-bottom: 1rem;
            position: relative;
            padding: 0;
            text-transform: capitalize;
            max-width: 30%;
            opacity: 0.8;
            // &.active {
            //   font-style: normal;
            //   font-weight: 700;
            //   font-size: 2rem;
            //   line-height: 2.6rem;
            //   color: #961ea8;
            //   display: block;
            //   padding-left: 2.4rem;
            //   &::after {
            //     background: #961ea8;
            //     width: 0.8rem;
            //     height: 0.8rem;
            //     content: "";
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     bottom: 0;
            //     margin: auto;
            //     border-radius: 50%;
            //   }
            // }
            &:hover {
              opacity: 1;
              color: #373676;
              transform: translateX(1rem);
            }
          }
        }
        .mainRight {
          width: 70%;
          ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 40vh;
            justify-content: flex-start;
            li {
              margin-bottom: 1.4rem;

              a {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 1.9rem;
                letter-spacing: -0.01em;
                color: #1d141f;
                opacity: 0.88;
                text-transform: capitalize;
                padding: 0;
                &:hover {
                  opacity: 1;
                  color: #373676;
                }
              }
            }
          }
        }
      }
      &:hover {
        .menuInner {
          display: block;
        }
      }
    }
  }
}
.supportContentHtmlEditor img {
  width: 100%;
  height: 40rem;
  object-fit: cover;
  margin: 2rem 0;
}
.innerBannerSlide {
  height: 20.5rem;
  border: 1px solid #55175e;
  border-radius: 8px;
  margin: 2rem 0;
  img {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
  }
}
.topSlider {
  height: 60rem;
  .swiper-slide,
  .swiper,
  .imgContainer {
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h1 {
      font-family: "Noto Sans", sans-serif !important;
      color: #fff;
      text-align: left;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.075rem;
      text-transform: uppercase;
      position: absolute;
      left: 20%;
      top: 20%;
      width: 40%;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 6.2rem;
    height: 6.2rem;
    background-color: #fff;
    border-radius: 50%;
    &::after {
      height: 2rem;
      width: 1.2rem;
      font-size: 2.2rem;
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
    }
    &.swiper-button-prev {
      &::after {
        background-image: url(./assets/left.svg);
      }
    }
    &.swiper-button-next {
      &::after {
        background-image: url(./assets/right.svg);
      }
    }
  }
}
.detail {
  .imgContainer {
    width: 100%;
    height: 70vh;
    border-radius: 2.4rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.InfoBar {
  padding: 4.4rem 0;
  .infoBardCard {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 10.8rem;
      height: 10.8rem;
      min-width: 10.8rem;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 135%;
      color: #1d141f;
      padding-left: 1.6rem;
    }
  }
}
.allCategory {
  background: #ede7ef;
  padding: 6.4rem 0 8.4rem;
}
.titleHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  p {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 5.4rem !important;
    line-height: 7.3rem !important;
    letter-spacing: -0.0101em !important;
    color: #430d4b !important;
  }
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: -0.03em;
    color: #000000;
    transition: 0.4s;

    svg {
      margin-left: 1.4rem;
      path {
        transition: 0.4s;
      }
    }
    &:hover {
      color: #cd9adc;

      svg {
        path {
          fill: #cd9adc;
        }
      }
    }
  }
}
.categoryCard {
  .categoryCardInner {
    position: relative;
    padding-top: 100%;
    height: 100%;
    object-fit: contain;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    .img {
      background: #cd9adc;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 135%;
    text-align: center;
    color: #1d141f;
    margin-top: 2.4rem;
  }
}
.productSliders {
  padding: 6rem 0;
}

.breadCrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.8rem 0;
  a {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.0099em;
    color: #1d141f;
    border-bottom: 0.1rem solid #d0cbd1;
    margin-right: 2.8rem;
    padding-bottom: 0.2rem;
    &::after {
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0.4rem;
      height: 0.4rem;
      background: #1d141f;
      right: -1.4rem;
      border-radius: 50%;
    }
    &:last-child {
      color: #c7c6d6;
      border-bottom: 0;
      pointer-events: none;
      &::after {
        display: none;
      }
    }
  }
}

.badge {
  position: absolute;
  top: 1.7rem;
  left: 1.2rem;
  background: #e9cdf1;
  border: 0.1rem solid #ffffff;
  border-radius: 2.8rem;
  font-style: normal;
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 116.1%;
  text-align: center;
  letter-spacing: -0.02em;
  padding: 0.7rem 1.6rem;
  z-index: 3;
}
.deliveryFee {
  font-size: 1.2rem !important;
  opacity: 0.6;
  span {
    font-size: 1.6rem !important;
    svg {
      width: 12px !important;
      height: 12px !important;
    }
  }
}
.discount {
  position: absolute;
  top: 1.7rem;
  right: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 116.1%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #f73200;
  border: 0.09rem solid #dcd4dd;
  border-radius: 2.8rem;
  padding: 0.7rem 1rem;
  z-index: 3;
}
.productCard {
  background: #ffffff;
  border: 0.1rem solid #eeecee;
  border-radius: 1.5rem;
  margin-bottom: 2.4rem;
  .productCardTop {
    position: relative;
    padding: 1.7rem 2rem 1.4rem 1.2rem;
    height: 26rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 22rem;
      height: 22rem;
      width: 100%;
      transition: 0.4s;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .rating {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2.4rem;
      svg {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0.4rem;
      }
    }
  }
  .productCardBody {
    padding: 0 2rem 2rem 2.8rem;
    p {
      font-style: normal;
      font-weight: 800;
      font-size: 1.8rem;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #1d141f;
      margin-bottom: 2.4rem;
      height: 7.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      strong {
        font-style: normal;
        font-weight: 800;
        font-size: 2.6rem;
        line-height: 116.1%;
        letter-spacing: -0.02em;
        color: #961ea8;
        svg {
          width: 1.6rem;
          height: 1.2rem;
          margin-left: 0.4rem;
        }
      }
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 2.1rem;
        line-height: 116.1%;
        letter-spacing: -0.02em;
        color: #555155;
        position: relative;
        display: block;
        svg {
          width: 1.6rem;
          height: 1.2rem;
          margin-left: 0.4rem;
        }
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 0.1rem;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background-color: #663173;
          transform: rotate(10deg);
        }
      }
    }
  }
  .productCardBottom {
    margin-top: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .addToCart {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: #430d4b;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      border: 0.1rem solid #e2e2e9;
      border-radius: 0.8rem;
      margin-right: 1rem;
      background-color: transparent;
      width: 70%;
      padding: 0;
      svg {
        margin-right: 1rem;
        width: 2.4rem;
        height: 2.4rem;
        g {
          opacity: 1;
        }
        path {
          fill: #430d4b;
          transition: 0.4s;
        }
      }
      &:hover {
        background-color: #430d4b;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    .likeButton {
      height: 4rem;
      border: 0.1rem solid #e2e2e9;
      border-radius: 0.8rem;
      width: 25%;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
      &:hover {
        background-color: #430d4b;
        color: #fff;
        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
  &:hover {
    .img {
      transform: scale(1.1);
    }
  }
}
.swiper-pagination-bullet {
  background: #e2e2e9 !important;
  opacity: 0.6 !important;
  width: 0.6rem !important;
  height: 0.6rem !important;
  margin: 0 0.3rem !important;
  &.swiper-pagination-bullet-active {
    opacity: 1 !important;
  }
}
.productSliders {
  .swiper {
    padding-bottom: 5rem;
    .swiper-pagination {
      z-index: 1;
    }
    .swiper-button-next,
    .swiper-button-prev {
      z-index: 2;
      left: 0;
      right: 0;
      margin: auto;
      top: auto;
      bottom: 0.6rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: "";
        background-image: url(./assets/arrowLong.svg);
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
      }
    }
    .swiper-button-next {
      right: -9rem;
      &::after {
        transform: rotate(-180deg);
      }
    }
    .swiper-button-prev {
      left: -9rem;
    }
  }
}
.serviceSection {
  background-color: #fbf9fb;
  padding: 6.5rem 0 8.6rem;
}
.serviceCard {
  border-radius: 1.3rem;
  overflow: hidden;
  height: 36.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .serviceCardTop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 31.5rem;
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.4s;
    }
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.5rem;
    letter-spacing: 0.0099em;
    color: #ffffff;
    background: #dd960d;
    height: 5.5rem;
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}
.sliderParent {
  margin-bottom: 4.2rem;
}
.productDes {
  margin-bottom: 3.2rem;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 116.1%;
    letter-spacing: -0.02em;
    color: #430d4b;
    margin-bottom: 2rem;
    display: block;
  }
  p {
    color: #1d141f;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 169.1%;
    letter-spacing: -0.012rem;
    width: 90%;
  }
}
.banner {
  padding: 4.8rem 0 8rem;

  .bannerCard {
    height: 25.6rem;
    width: 100%;
    overflow: hidden;
    border: 0.1rem solid #eee7ef;
    border-radius: 3rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      img {
        transform: scale(1.06);
      }
    }
  }
}
.brandsPageLink {
  width: auto;
  justify-content: center;
  border-radius: 8px;
  display: flex;
  padding: 2rem 3rem;
  box-shadow: rgba(149, 157, 165, 0.1) 0 0.1rem 2.4rem;
  img {
    width: 10rem;
    object-fit: contain;
    &:hover {
      scale: 0.9;
      filter: grayscale(90%);
    }
  }
}

.brands {
  background: #fbf9fb;
  padding: 3.2rem 0 6.8rem;
  .brandsCard {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17.4rem;
    margin-bottom: 1.6rem;
    box-shadow: rgba(149, 157, 165, 0.1) 0 0.1rem 2.4rem;
    img {
      max-height: 10rem;
      width: 70%;
      object-fit: contain;
      mix-blend-mode: multiply;
    }
    &:hover {
      img {
        filter: grayscale(100%);
        transform: scale(1.05);
      }
    }
  }
}
.videos {
  position: relative;
  display: flex;
  justify-items: center;
  flex-wrap: wrap;
  column-gap: 1.25rem;
  .videosPage {
    width: 100%;
    min-height: 20.875rem;
    border-radius: 2.375rem;
    border-radius: 2.375rem;
    background: lightgray 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      word-break: break-all !important;
    }
    button {
      border: none;
      background-color: transparent;
      svg {
        width: 3.875rem;
        height: 3.875rem;
        flex-shrink: 0;
        &:hover {
          scale: 1.2;
        }
      }
    }
  }
}
.blogSection {
  background: #eee8f0;
  padding: 6.2rem 0 12rem;
}
.blogCard {
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
  height: 50.8rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.4s all ease;
  }
  .layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #7b7a8d;
    mix-blend-mode: multiply;
    transition: 0.4s all ease;
    z-index: 2;
  }
  .layerContent {
    position: absolute;
    left: 0;
    top: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 4rem 0 0 4rem;
    transition: 0.4s all ease;
    z-index: 2;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 3.3rem;
      line-height: 116.1%;
      letter-spacing: -0.02em;
      color: #ffffff;
      display: block;
      transition: 0.4s all ease;

      margin-bottom: 2rem;
    }
    svg {
      width: 3.2rem;
      height: 2.8rem;
    }
  }
  &:hover {
    .layer {
      background-color: transparent;
      mix-blend-mode: none;
    }
    img {
      transform: scale(1.2);
    }
  }
}

.seoSection {
  display: none;
  background-color: #f9f7fa;
  padding: 7.2rem 0 6.4rem;
  position: relative;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.2rem;
    letter-spacing: 0.0099em;
    color: #430d4b;
    margin-bottom: 1.8rem;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 165%;
    letter-spacing: -0.0101em;
    color: #1d141f;
    opacity: 0.6;
    margin-bottom: 2.6rem;
  }
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: -0.02em;
    color: #1d141f;
  }
}

.PageTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .purpleButton {
    width: fit-content;
    padding: 0 4.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.pageTitle {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  margin: 4rem 0 2.4rem;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 4.4rem;
    line-height: 6rem;
    // text-align: center;
    letter-spacing: 0.0099em;
    color: #430d4b;
  }
}
.page {
  padding-bottom: 9rem;
  .productSliders {
    padding: 1rem 0;
  }
  .serviceCard,
  .blogCard {
    margin-bottom: 2.4rem;
  }
  .pageDetail {
    .pageDetailTitle {
      margin-top: 5.4rem;
      border-bottom: 0.1rem solid rgba(226, 186, 221, 0.6);
      padding-bottom: 2rem;
      margin-bottom: 6.4rem;
      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 4.9rem;
        letter-spacing: -0.02em;
        color: #1d141f;
      }
    }
    p,
    strong,
    span,
    a {
      font-style: normal;
      font-weight: 300;
      font-size: 2rem;
      line-height: 165%;
      letter-spacing: -0.02em;
      color: #1d141f;
      margin-bottom: 3.2rem;
      width: 100%;
      p,
      strong,
      span,
      a {
        margin-bottom: 0;
        width: 100%;
      }
    }
    strong {
      font-weight: 700;
    }
    p a {
      font-weight: 500;
      display: inline;
      color: #430d4b;
      border-bottom: 0.1rem solid #430d4b;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 125.1%;
    letter-spacing: -0.02em;
    color: #1d141f;
    margin-bottom: 4rem;
    width: 75%;
    word-break: break-all;
  }
  h3 {
    line-height: 125.1%;
  }
  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.8rem;
  }
  h6 {
    font-size: 1.6rem;
  }
  ul {
    padding-left: 2.4rem;
    margin-bottom: 4.8rem;
    li {
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 183.5%;
      letter-spacing: -0.02em;
      color: #1d141f;
      list-style-type: disc;
      margin-bottom: 1rem;
      width: 80%;
    }
  }
}
.alreadyRegistered {
  font-size: 1.4rem;
  font-weight: 500;
  color: #430d4b;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.cartGuestOrder {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  label {
    display: flex;
    flex-direction: column;
    color: #000;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    opacity: 0.48;
    gap: 1rem;
  }
}
.campaignImg {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: 5rem;
  img {
    width: 100%;
    height: 50rem;
    object-fit: contain;
  }
}
.guestOrderName {
  width: 100%;
  border: 0.1rem solid #cabecd;
  border-radius: 0.8rem;
  display: flex;
  height: 4.8rem;
  input {
    width: 100%;
    border: none;
    border-radius: 0;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: -0.02em;
    color: #000000;
    background-color: transparent;
    padding: 0 2rem;
  }
}
footer {
  background: #430d4b;
  margin-top: 5.2rem;
  .footerTop {
    border-bottom: 0.2rem solid #55175e;

    padding: 6rem 0 4.2rem;
    .footerTopInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .footerTopInnerLeft {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .footerContactDiv {
        border-right: 0.15rem solid #55175e;
        padding-right: 3.2rem;
        a,
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.4rem;
          letter-spacing: -0.03em;
          color: rgba(255, 255, 255, 0.88);
          transition: 0.4s;
        }
        a {
          &:hover {
            opacity: 0.5;
          }
        }
        span {
          display: block;
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 2rem;
          letter-spacing: -0.01em;
          color: #f7f7f7;
          opacity: 0.4;
          margin-top: 0.8rem;
        }
      }
    }
    .socials {
      display: flex;
      justify-content: flex-end;
      margin-left: 4rem;
      a {
        width: 4.4rem;
        height: 4.4rem;
        border: 0.15rem solid #7c507c;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1.6rem;
        border-radius: 50%;
        transition: 0.4s;

        svg {
          width: 2rem;
          height: 2rem;

          path {
            fill: #7c507c;
            transition: 0.4s;
          }
        }
        &:hover {
          border: 0.15rem solid #fff;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  .footerMiddle {
    padding: 5.4rem 0;
    display: flex;
    .footerDiv {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      ul {
        width: 20%;
        margin-bottom: 2.4rem;

        li {
          margin-bottom: 1.6rem;

          &:first-child {
            margin-bottom: 2rem;
            a {
              font-style: normal;
              font-weight: 700;
              font-size: 2.8rem;
              line-height: 3.6rem;
              letter-spacing: 0.0099em;
              color: #ffffff;
              opacity: 1;
              transition: 0.4s;
              &:hover {
                opacity: 0.6;
              }
            }
          }
          a {
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2rem;
            letter-spacing: 0.0099em;
            color: #ffffff;
            opacity: 0.46;
            transition: 0.4s;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .footerBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5.4rem 0 6.8rem;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0.0099em;
      color: #ffffff;
      display: flex;
      justify-content: flex-start;

      strong {
        display: block;
        font-weight: 800;
        text-transform: uppercase;
        margin-left: 0.6rem;
      }
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.1rem;
      letter-spacing: 0.0099em;
      color: rgba(255, 255, 255, 0.45);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: 0.0099em;
        color: #ffffff;
        border-bottom: 0.06rem solid #ffffff;
        margin-left: 2rem;
        padding-bottom: 0.2rem;
        transition: 0.4s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
.products {
  .filterParent {
    z-index: 5;
  }
  .seoSection {
    background-color: transparent;
    margin: 4rem 0 0;
  }
  .filter__control {
    height: 3.5rem;
    border: 0.1rem solid #d6cad8;
    border-radius: 1.6rem;
    min-width: 17rem;
    background-color: transparent !important;
    cursor: pointer;
    z-index: 10;

    .filter__value-container {
      padding: 0 1rem 0 2rem;
    }
    .filter__single-value {
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem !important;
      line-height: 1.9rem !important;
      color: #430d4b !important;
    }
    .filterInner {
      margin-top: -3rem !important;
      display: flex;
      align-items: center;
      justify-content: center !important;
      gap: 0.2rem;
      position: relative;
      z-index: 0;
    }
    .chooseFilter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .filter__indicator {
      svg {
        path {
          fill: #430d4b;
        }
      }
    }
  }
  .subProductsTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .subProductsLeft {
      display: flex;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: -0.0101em;
        color: #1d141f;
        opacity: 0.7;
        display: block;
        margin-right: 1.2rem;
      }
    }
  }
}
.productsParents {
  .productsTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.8rem;
    margin-top: 4rem;
    .titleParent {
      display: flex;
      align-items: center;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  h2,
  .productCount {
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.3rem;
    letter-spacing: -0.0101em;
    color: #1d141f;
  }
  .productCount {
    display: block;
    margin-left: 0.6rem;
  }
  .productLeftTop {
    height: 5.6rem;
    border: 0.1rem solid #dcd4dd;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.8rem 0 2rem;
    margin-bottom: 2.6rem;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.2rem;
      letter-spacing: -0.0101em;
      color: #430d4b;
    }
    button {
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: -0.0101em;
      color: rgba(29, 20, 31, 0.9);
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      border: none;
      background-color: transparent;
      svg {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.4rem;
      }
    }
  }
  .filterCard {
    border-bottom: 0.1rem solid #e2e2e9;
    padding-bottom: 2.6rem;
    margin-bottom: 2.4rem;
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.7rem;
      letter-spacing: -0.0001em;
      color: #1d141f;
      margin-bottom: 1.6rem;
      text-transform: capitalize;
    }
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.9rem;
      letter-spacing: -0.0101em;
      color: #1d141f;
      opacity: 0.8;
      transition: 0.4s;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      margin-bottom: 1.4rem;
    }
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      margin-bottom: 1.4rem;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: -0.0101em;
        color: #1d141f;
        opacity: 0.8;
        transition: 0.4s;
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 2rem;
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        margin: 0;
      }
      input:checked ~ span {
        color: #961ea8;
      }
    }
    &.filterPrice {
      span {
        font-size: 1.6rem;
        opacity: 1;
      }
    }
    &.colorInputParent {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      p {
        width: 100%;
      }
      .colorInput {
        margin-right: 1.8rem;
        span {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          display: block;
          min-width: 2.5rem;
        }
      }
    }
    &.filterBrands {
      label {
        div {
          width: 100%;
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.9rem;
          color: #1d141f;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font-weight: 300;
            opacity: 0.7;
          }
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}
.searchFilter {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.4rem;
  height: 3.8rem;
  border: 0.1rem solid #e1e1e9;
  border-radius: 0.8rem;

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  input {
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: 0.01em;
    width: 100%;
    border: none;
    background-color: transparent;
    color: #000000;
    &::placeholder {
      color: rgba(142, 142, 153, 0.6);
    }
  }
}
.search:focus-within {
  .searchSection {
    display: block;
  }
}
.searchProduct {
  .searchEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      color: #430d4b;
      padding: 1rem;
    }
    svg {
      width: 20rem;
      height: auto;
    }
  }
}

.searchSection {
  position: absolute;
  top: 6.4rem;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(196, 196, 196, 0.2);
  border-radius: 0.8rem;
  width: 100%;
  max-height: 52.4rem;
  height: fit-content;
  z-index: 5;
  overflow: hidden;
  display: none;

  .searchSectionInner {
    padding: 2.2rem 1rem;
    max-height: 42rem;
    overflow: auto;
    .searchEmpty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        color: #430d4b;
        padding: 1rem;
      }
      svg {
        width: 20rem;
        height: auto;
      }
    }
  }
}
.moreButton {
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.7rem;
  letter-spacing: -0.03em;
  color: #000000;
  background-color: #fff;
  padding: 2rem;
  svg {
    width: 2.4rem;
    height: 0.8rem;
    margin-left: 1rem;
  }
}
.searchCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.8rem;
  transition: 0.4s;
  margin-bottom: 1rem;
  .img {
    background: #ffffff;
    border: 0.03rem solid #eae6eb;
    border-radius: 1.2rem;
    width: 5.8rem;
    height: 5.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    img {
      width: 4rem;
      height: 4rem;
      object-fit: contain;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 122.1%;
    letter-spacing: -0.02em;
    color: #1d141f;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 60%;
  }
  .price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    div {
      font-style: normal;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 116.1%;
      text-align: right;
      letter-spacing: -0.02em;
      color: #555155;
      position: relative;
      padding-left: 0;
      width: fit-content;
      svg {
        width: 0.7rem;
        height: 0.5rem;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 0.1rem;
        background-color: #663173;
        transform: rotate(20deg);
      }
    }
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 116.1%;
      text-align: right;
      letter-spacing: -0.02em;
      color: #961ea8;
      display: flex;
      align-items: center;
      svg {
        width: 1rem;
        height: 0.9rem;
        margin-left: 0.4rem;
      }
    }
  }
  .searchCardLeft {
    display: flex;
    align-items: center;
  }
  &:hover {
    background-color: #f4f1f4;
  }
}
.filterBrandParent {
  height: 20rem;
  width: -webkit-fill-available;
  padding: 0 2rem 0 0;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.6rem;
}
::-webkit-scrollbar-track {
  background: #ebeae6;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background: #691475;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
  opacity: 0.5;
}
.checkboxInput {
  position: relative;
  padding-left: 3.2rem;
  margin-bottom: 2.4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: transparent;
    display: block;
    border: 0.1rem solid rgba(67, 13, 75, 0.3);
    border-radius: 0.5rem;
  }
  input:checked ~ .checkmark {
    background-color: #961ea8;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 0;
    top: 0;
    bottom: 0.3rem;
    right: 0;
    margin: auto;
    width: 0.35rem;
    height: 0.8rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.auth {
  .breadCrumb {
    margin-bottom: 2rem;
  }
  .img {
    overflow: hidden;
    border-radius: 4rem;
    padding-top: 100%;
    position: relative;
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .authContainerParent {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  .authContainerBody {
    p,
    p a {
      font-style: normal;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 116.1%;
      color: #1d141f;
    }
    p a {
      font-weight: 500;
      display: inline;
      padding-bottom: 0.4rem;
      border-bottom: 0.1rem solid rgba(61, 59, 158, 0.4);
    }
  }
  .authContainerHeader {
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 4.8rem;
      line-height: 6.5rem;
      text-align: center;
      letter-spacing: -0.02em;
      color: #430d4b;
      margin-bottom: 3.2rem;
    }
  }

  .auth-class {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 11;
      line-height: 116.1%;
      color: #430d4b;
      border-bottom: 0.1rem solid rgba(61, 59, 158, 0.4);
      padding-bottom: 0.4rem;
      transition: 0.4s;

      &:hover {
        color: #000;
        border-bottom: 0.1rem solid #000;
      }
    }
  }
}
.buttons {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  button {
    margin-right: 2rem;
  }
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 116.1%;
    text-align: center;
    color: rgba(67, 13, 75, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #cabecd;
    border-radius: 0.8rem;
    width: 100%;
    transition: 0.4s;

    height: 4.8rem;
    &:hover {
      background-color: #430d4b;
      color: #fff;
    }
  }
}
.purpleButton {
  width: 100%;
  height: 4.8rem;
  background: #430d4b;
  border-radius: 0.8rem;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 116.1%;
  text-align: center;
  color: #ffffff;
  border: none;
  transition: 0.4s;
  padding: 0;
  &:hover {
    background-color: #000000;
  }
}
.profile {
  .pageTitle {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4rem;
    h1 {
      text-align: left;
      font-style: normal;
      font-weight: 700;
      font-size: 5.4rem;
      line-height: 116.1%;
      letter-spacing: -0.01em;
      color: #1d141f;
    }
  }
  .profilePic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.3rem;
    height: 7.3rem;
    border: 0.1rem solid rgba(151, 40, 138, 0.2);
    padding: 0.6rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 2.4rem;
    img {
      width: 7.7rem;
      height: 7.7rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
.profileCard {
  background: #651471;
  border-radius: 1.6rem;
  width: 100%;
  height: 30.5rem;
  .profileCardInner {
    padding: 4rem 4.6rem;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 3.2rem;
      line-height: 96.1%;
      color: #ffffff;
      display: block;
      height: 6.2rem;
      margin-bottom: 1.8rem;
    }
    svg {
      width: 14rem;
      height: 12rem;
      object-fit: contain;
    }
  }
}
.productDetail {
  padding-bottom: 0;
  .productSliders {
    padding-top: 6.4rem;
  }
  .InfoBar {
    margin-top: 4.4rem;
    background: #f1edf3;
  }
  .productDetailTop {
    padding: 3.6rem 0;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 4.9rem;
      letter-spacing: -0.02em;
      color: #1d141f;
      margin-bottom: 0.8rem;
    }
    .productDetailTopInner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 1.2rem;
      border-bottom: 0.1rem solid #e2e2e9;
      .rating {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 0.1rem solid #e1e1e9;
        padding-right: 2rem;
        width: fit-content;
        margin-right: 2.4rem;
        svg {
          width: 1.1rem;
          height: 1.1rem;
          margin-right: 0.8rem;
        }
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 2rem;
          letter-spacing: -0.02em;
          color: #1d141f;
          display: block;
          margin-left: 0.6rem;
        }
      }
      .share {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-right: 0.1rem solid #e1e1e9;
        padding-right: 2.8rem;
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 1.3rem;
          line-height: 116.1%;
          letter-spacing: -0.02em;
          color: #1d141f;
          opacity: 0.4;
          display: block;
          margin: 0 3rem 0 0;
        }
        a {
          margin-right: 1rem;
          width: 3.2rem;
          height: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 2rem;
            height: 2rem;
            path {
              fill: rgba(73, 73, 129, 0.6);
              opacity: 1;
              fill-opacity: 1;
            }
            g {
              opacity: 1;
            }
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            svg {
              path {
                fill: rgba(67, 13, 75, 1);
              }
            }
          }
        }
      }
      .fav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 3rem;
        span {
          font-style: normal;
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 2rem;
          letter-spacing: -0.02em;
          color: #430d4b;
          display: block;
          margin-left: 1rem;
        }
        label {
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 1rem;

          input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            margin: 0;
            cursor: pointer;
            opacity: 0;
          }
          div {
            width: 4.3rem;
            height: 3.5rem;
            border: 0.1rem solid #e2e2e9;
            border-radius: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 1.8rem;
              height: 1.8rem;
            }
          }
          input:checked ~ div {
            svg {
              path {
                fill: #430d4b;
              }
            }
          }
        }
      }
    }
  }
}

.sliderParent {
  position: sticky;
  top: 2.4rem;
  .mySwiper2 {
    height: 42.3rem;
    margin-bottom: 1.2rem;

    .slideItem {
      height: 100%;
      background: #ffffff;
      border: 0.1rem solid #f0f0f0;
      border-radius: 1.5rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 60%;
        height: 60%;
        object-fit: contain;
      }
    }
  }
  .mySwiper {
    .swiper-wrapper {
      display: flex;
      justify-content: center;
    }
    .slideItemChild {
      height: 100%;
      background: #ffffff;
      border: 0.1rem solid #f0f0f0;
      border-radius: 0.5rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.5rem;
      img {
        width: 90%;
        height: 90%;
        object-fit: contain;
      }
    }
  }
}
.Collapsible {
  margin-top: 2rem;
  border-bottom: 0.1rem solid #e1e1e9;
  cursor: pointer;
  .Collapsible__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 116.1%;
    letter-spacing: -0.02em;
    color: #430d4b;
    padding: 0 1rem 2rem;

    .plus {
      background: #1d141f;
      width: 1rem;
      height: 0.1rem;
      display: block;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background: #1d141f;
        height: 1rem;
        width: 0.1rem;
        transition: 0.4s;
      }
    }

    &.is-open {
      .plus {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }
  .Collapsible__contentInner {
    margin-bottom: 0;
    padding-bottom: 2rem;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 169.1%;
      letter-spacing: -0.01em;
      color: #1d141f;
      margin-bottom: 1rem;
    }
    .brandName {
      display: flex;
      align-items: center;
      margin-bottom: 1.6rem;
      span {
        font-size: 1.6rem;
        display: block;
        margin-right: 0.6rem;
        margin-bottom: 0;
      }
      strong {
        display: block;
        margin-left: 0.6rem;
        font-size: 1.6rem;
        color: #430d4b;
      }
    }
  }
  .Collapsible__contentOuter {
    // overflow: auto !important;
    padding-left: 1.6rem;
  }
  .collapseBody {
    padding: 0 1.6rem;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 116.1%;
      letter-spacing: -0.02em;
      color: #1d141f;
      opacity: 0.6;
      // margin-bottom: 1.8rem;
      display: block;
    }
    ul {
      padding-left: 0;
    }
    .select__control {
      background-color: transparent;
      border: 0.1rem solid #e1e1e9 !important;
      border-radius: 0.8rem;
      overflow: hidden;
      z-index: 4;
      margin-bottom: 3.2rem;
      outline: none !important;
      box-shadow: none !important;
      .select__value-container {
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 116.1%;
        letter-spacing: -0.02em;
        color: #1d141f;
        opacity: 1 !important;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      min-width: 50%;
      width: fit-content;
      margin-bottom: 2.4rem;
      li {
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 116.1%;
        letter-spacing: -0.02em;
        color: rgba(29, 20, 31, 0.4);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.4rem;
        white-space: nowrap;

        span {
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 116.1%;
          letter-spacing: -0.02em;
          color: #1d141f;
          border-bottom: 0.05rem solid #1d141f;
          padding-bottom: 0.18rem;
          white-space: nowrap;
          margin-left: 0.6rem;
        }
      }
    }
  }
}
.profileLogoutParent {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;

  .profileLogout {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 116.1%;
    letter-spacing: -0.02em;
    color: #1d141f;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
    padding: 0;
    border: none;
    svg {
      margin-left: 1.6rem;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}
.productDetailCard {
  flex-shrink: 0;
  border-radius: 0.9375rem;
  border: 1px solid #d1d1dc;
  padding: 1rem 1.25rem;
  margin-bottom: 2rem;
  h3 {
    color: #1d141f;
    font-family: "Noto Sans";
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 161.1%; /* 1.30894rem */
    letter-spacing: -0.01625rem;
    margin-bottom: 0.5rem;
  }
  .cardSelect {
    padding: 0.4rem;
    .cardTitles {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      text-align: center;
      :first-child {
        text-align: start;
        min-width: 9rem;
      }
      span {
        width: 100%;
        color: #1d141f;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: 161.1%;
        letter-spacing: -0.01125rem;
        opacity: 50%;
      }
    }
    .cardBody {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      margin: 1rem 0;
      .bank {
        display: flex;
        justify-content: flex-start !important;
        min-width: 9rem;
      }
      .card {
        width: 100%;
        display: flex;
        img {
          width: 2.4rem;
        }
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.3rem;
          color: #1d141f;
          font-size: 1.1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 161.1%;
          svg {
            width: 0.9rem;
            margin-left: 0.2rem;
          }
          select {
            font-size: 1.1rem;
            color: #1d141f;
            text-align: center;
            border: 1px solid #d1d1dc;
            width: 80%;
            border: none;
            border-radius: 4px;
            background-color: transparent;
          }
        }
      }
    }
  }
}
.promoCode {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.productOneClickPurchase {
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
  h3 {
    color: #000;
    font-family: "Noto Sans";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    opacity: 0.48;
    margin-bottom: 0.8rem;
  }
  .oneClickInput {
    border: 1px solid #cabecd;
    border-radius: 12px;
    padding: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      width: 100%;
      margin-right: 1rem;
      height: 100%;
      color: #000;
      font-family: "Noto Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.28px;
      border: none;
      background-color: transparent;
      opacity: 0.48;
    }
    span {
      color: #000;
      font-family: "Noto Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
      margin: 0 1.1rem 0 2.6rem;
      width: max-content;
    }
    button {
      border-radius: 4px;
      background: #961ea8;
      border: 0;
      color: #fff;
      font-family: "Noto Sans";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 116.1%;
      letter-spacing: 0.42px;
      padding: 1rem 3.4rem;
      flex-shrink: 0;
      &:hover {
        background-color: #75527a;
      }
    }
  }
}
.productDetailRight {
  border: 0.1rem solid #cdcdda;
  border-radius: 1.5rem;
  padding: 3rem 4rem;
  margin-bottom: 2rem;
  .price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 3.7rem;
    line-height: 116.1%;
    color: #961ea8;
    margin-bottom: 1.2rem;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 3.7rem;
      line-height: 116.1%;
      color: #961ea8;
    }
    .discountPrice {
      font-style: normal;
      font-weight: 400;
      font-size: 2.6rem;
      line-height: 116.1%;
      letter-spacing: -0.02em;
      color: #1d141f;
      display: flex;
      align-items: center;
      position: relative;
      opacity: 0.5;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 0.1rem;
        background-color: #430d4b;
        transform: rotate(12.96deg);
      }
      svg {
        width: 1.4rem;
        height: 1.2rem;
        margin-left: 0.8rem;
        path {
          fill: #1d141f;
        }
      }
    }
  }
  .cards {
    display: flex;
    align-items: center;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 116.1%;
      letter-spacing: -0.02em;
      color: #847082;
      display: block;
      margin-right: 3.5rem;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.6rem;
      height: 3.6rem;
      background: #f6f6f7;
      border: 0.1rem solid #e2e2e9;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 0.6rem;
      img {
        width: 3rem;
        height: 3rem;
        object-fit: contain;
      }
    }
  }
  .cardCheckout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.8rem;
    button.addToCart {
      width: 50%;
      background: #430d4b;
      border-radius: 0.8rem;
      height: 4.8rem;
      font-style: normal;
      font-weight: 500;
      font-size: 1.7rem;
      line-height: 2.4rem;
      text-align: center;
      letter-spacing: -0.02em;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      svg {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1rem;
        path {
          fill: #fff;
        }
      }
      &:hover {
        background-color: #000;
      }
    }
  }
}
.quantity-picker {
  border: 0.12rem solid #3c3b9e !important;
  border-radius: 1rem !important;
  height: 4.8rem;
  overflow: hidden;
  width: 13rem;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  .quantity-display {
    background-color: transparent;
  }
}
.productDetailPrice {
  border: 0.1rem solid #cdcdda;
  border-radius: 1.5rem;
  padding: 2rem 3rem;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 161.1%;
    color: #1d141f;
    margin-bottom: 1.2rem;
  }
  table {
    width: 100%;
    tr {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.2rem;
      th,
      td {
        width: 20%;
      }
    }
    th {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 161.1%;
      color: #1d141f;
      opacity: 0.5;
      text-align: center;
    }
    td {
      font-style: normal;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 161.1%;
      color: #1d141f;
      text-align: center;
    }
  }
}
.calculator {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  button {
    width: 100%;
    margin-right: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child {
      margin-right: 0;
    }
  }
  .credit {
    height: 3.6rem;
    background: #434396;
    border-radius: 0.4rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 116.1%;
    color: #ffffff;
    border: none;
    &:hover {
      background-color: #000;
    }
  }
  .calculatorButton {
    height: 3.6rem;
    border: 0.1rem solid #ddddee;
    border-radius: 0.4rem;
    background-color: transparent;
  }
}
.aboutCard {
  background: #f6f3f7;
  border-radius: 3rem;
  padding: 4.8rem 0 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
  .aboutCardInner {
    width: 70%;
  }
  .statsNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    h3 {
      width: auto;
      margin: 2.6rem 0;
    }
    p {
      font-size: 2.6rem;
      margin: 0;
      margin-top: 0.5rem;
    }
  }
}
.about {
  padding-bottom: 0;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 165%;
    color: #1d141f;
    opacity: 0.6;
    margin-bottom: 2.4rem;
  }
  h1,
  h2,
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 4.4rem;
    line-height: 6rem;
    text-align: center;
    letter-spacing: 0.0099em;
    color: #430d4b;
    margin-bottom: 3rem;
    width: 100%;
  }
  h3,
  h4 {
    margin-top: 4rem;
  }
}
.aboutImg {
  height: 100vh;
  width: 99vw;
  margin-left: -50vw;
  margin-right: -50vw !important;
  position: relative;
  left: 50%;
  right: 50%;
  display: block;
  margin-top: -8rem;
  z-index: 1;
  margin-bottom: -8rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3rem;
  }
}
.buyNow {
  color: #430d4b;
  text-align: center;
  font-size: 1.8826rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.0941rem;
  text-transform: uppercase;
  padding: 0 4.2rem;
  border-radius: 2.5672rem;
  background: #fbf9fb;
  height: 4.8rem;
  position: absolute;
  left: 20%;
  width: fit-content;
  bottom: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watchButton {
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 161.1%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #961ea8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 6.8rem;
  background: #ffffff;
  border-radius: 3.2rem;
  width: fit-content;
  padding: 0 3.6rem;
  transition: 0.3s;
  cursor: pointer;
  svg {
    width: 3rem;
    height: 3rem;
    margin-right: 1.2rem;
  }
  &:hover {
    background-color: #430d4b;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}
.aboutBottom {
  background: #ede9ef;
  padding: 6.4rem 0 8.2rem;
  .aboutBottomLeft {
    border-radius: 3rem;
    height: 55.5rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .aboutBottomRight {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 2.6rem;
      color: #430d4b;
      opacity: 0.6;
      margin-bottom: 0.3rem;
      display: block;
    }
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 3.3rem;
      line-height: 4.5rem;
      color: #1d141f;
      text-align: left;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .aboutBottomInner {
    padding-top: 6rem;
    svg {
      width: 4.1rem;
      height: 6rem;
    }
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
      letter-spacing: 0.0099em;
      color: #430d4b;
    }
  }
}
.gallery {
  margin: 2.4rem 0;
  .galleryCard {
    width: 100% !important;
    height: 29.6rem;
    border-radius: 0.7rem;
    overflow: hidden;
    a {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.galleryDetail {
  .galleryCard {
    height: 60rem;
  }
}
.videoGallery {
  height: 42.8rem;
  width: 100%;
  border-radius: 1.4rem;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  button {
    border: none;
  }
}
.share {
  border-top: 0.1rem solid #e5dce4;
  padding-top: 2.4rem;
  margin-top: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    display: flex;
    width: fit-content !important;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 155%;
    color: #121212;
    opacity: 0.5;
    margin-right: 3.2rem;
  }
  a {
    margin-right: 2.4rem;
    width: fit-content !important;
    svg {
      width: 2.1rem;
      height: 2.1rem;
    }
    &:hover {
      svg {
        path {
          fill: #55175e77;
        }
      }
    }
  }
}
.otherProducts {
  background: #fbf9fb;
  // padding: 6.4rem 0 8.2rem;
  margin-top: 6.2rem;
}
.detail {
  padding-bottom: 0;
}
.storeSide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.1rem solid #dcdceb;
  border-radius: 1.6rem;
  padding: 3rem 2.4rem;
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 116.1%;
    color: #1d141f;
    opacity: 0.6;
    position: relative;
    margin-bottom: 2.6rem;
    transition: 0.4s all ease;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: #430d4b;
      transition: 0.4s all ease;
      opacity: 0;
    }
    &.active {
      color: #430d4b;
      padding-left: 3rem;
      opacity: 1;
      font-style: normal;
      font-weight: 500;
      font-size: 1.7rem;
      line-height: 116.1%;
      color: #430d4b;
      margin-bottom: 3.6rem;
      transition: 0.4s all ease;

      &::before {
        opacity: 1;
        transition: 0.4s all ease;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.storeRight {
  padding-left: 2.4rem;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 127%;
    color: #1d141f;
    margin-bottom: 2.1rem;
  }
  .storeDetail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.2rem;
    p {
      width: 33%;
      font-style: normal;
      font-weight: 400;
      font-size: 1.7rem;
      line-height: 155%;
      letter-spacing: -0.02em;
      color: #1d141f;
      display: flex;
    }
  }
  .playButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14rem;
    height: 14rem;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    svg {
      width: 8rem;
      height: 8rem;
      path {
        fill: #fff;
      }
    }
  }
  .aboutImg {
    width: 100%;
    height: 42rem;
    margin: 0 !important;
    position: relative;
    left: auto;
    right: auto;
    margin-bottom: 4rem;
  }
  iframe {
    width: 100%;
    height: 47.4rem;
    border-radius: 3rem;
    border: none;
    margin-top: 4rem;
  }
}
.contactTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  .contactTopDetail {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    justify-content: space-between;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: -0.03em;
      color: #000;
      transition: 0.4s;
      span {
        font-weight: 700;
        font-size: 2.2rem;
        color: #430d4b;
      }
    }
  }
  .contactLink {
    border: 0.1rem solid #d2d2de;
    border-radius: 0.8rem;
    height: 6.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3.2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 116.1%;
    text-align: center;
    color: #1d141f;
    margin-right: 3.4rem;
    white-space: nowrap;
    transition: 0.3s;
    svg {
      width: 2.6rem;
      height: 2.6rem;
      margin-left: 3.2rem;
      path {
        fill: #000;
      }
    }
    &:hover {
      background: #ece7ed;
    }
  }
  .socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a {
      width: 4.4rem;
      height: 4.4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.13rem solid #d2d2de;
      margin-right: 1.3rem;

      svg {
        width: 2rem;
        height: 2rem;
        path {
          fill: #d2d2de;
        }
      }
      &:hover {
        border: 0.1rem solid #691475 !important;
        svg {
          path {
            fill: #691475 !important;
          }
        }
      }
    }
  }
}
.contactIframe {
  margin-bottom: 9rem;
  iframe {
    width: 100%;
    height: 49rem;
    border: none;
  }
}
.contactForm {
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 4.9rem;
    line-height: 6.7rem;
    letter-spacing: -0.01em;
    color: #1d141f;
    margin-bottom: 0.9rem;
    display: block;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 116.1%;
    color: #1d141f;
    margin-bottom: 5.4rem;
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: -0.02em;
    color: #000000;
    opacity: 0.48;
  }
}
.input-child {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: -0.02em;
    color: #000000;
    opacity: 0.48;
    margin-left: 0.8rem;
    margin-bottom: 0.4rem;
    display: block;
  }
  select {
    height: 4.8rem;
    border: 0.1rem solid #cabecd;
    border-radius: 0.8rem;
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0 2rem;
    background-color: transparent;
  }
  option {
    color: black !important;
  }
  input {
    width: -webkit-fill-available;
    height: 4.8rem;
    border: 0.1rem solid #cabecd;
    border-radius: 0.8rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: -0.02em;
    color: #000000;
    background-color: transparent;
    padding: 0 2.4rem;
    &::placeholder {
      opacity: 0.5;
    }
  }
}
.promo {
  font-size: 1.2rem !important;
  margin-top: 1rem;
}
.filterBrandParent {
  .filter__control {
    position: relative;
    svg {
      width: 2.8rem;
      margin-right: 0.8rem;
      height: 2.8rem;

      g {
        opacity: 1;
      }

      path {
        fill: #000000;
        transition: 0.3s;
      }

      #Star {
        path {
          stroke: #000000 !important;
        }
      }
    }

    .filter__value-container {
      height: 4.8rem;

      .filter__input-container {
        position: absolute;
        height: 4.8rem;

        input {
          height: 100%;
        }
      }
    }

    div {
      display: flex;
      align-items: center;
    }
  }
}
.numberSelect {
  width: 100%;
  border: 0.1rem solid #cabecd;
  border-radius: 0.8rem;
  display: flex;
  height: 4.8rem;
  .filter__indicators {
    display: none;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filter__control,
  span {
    width: 10rem;
    height: 100%;
    border: none;
    background-color: transparent;
  }
  .filter__single-value,
  span {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: -0.02em;
    color: #000000;
  }
  .filter__input-container,
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .filter__value {
    padding: 0;
  }
  input {
    width: 100%;
    border: none;
    border-radius: 0;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: -0.02em;
    color: #000000;
    background-color: transparent;
    border-left: 0.1rem solid #cabecd;
    padding-left: 3rem;
  }
}
.inputGroup {
  display: flex;
  align-items: flex-start;
  width: 100%;
  button {
    width: fit-content;
    padding: 0 5.4rem;
    margin-top: 2.7rem;
  }
  .input-child {
    width: 100%;
    margin-right: 1.6rem;
    &:last-child {
      margin-right: 0;
    }
  }
  textarea {
    width: -webkit-fill-available;
    height: 14.3rem;
    border: 0.1rem solid #cabecd;
    border-radius: 0.8rem;
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    resize: none;
    padding: 1.6rem;
    color: #000000;
    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.fav {
  .pageTitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    .svg {
      margin-right: 1.6rem;
      width: fit-content;
      svg {
        width: 3.6rem;
        height: 3.6rem;
      }
    }
  }
}

.productCardParent {
  .remove {
    display: none;
  }
}
.favorite {
  fill: #430d4b;
}
.favButton {
  display: flex;
  background-color: transparent;
  border: none;
}
.fav {
  .productCardParent {
    margin-bottom: 4.8rem;
    padding-bottom: 1.2rem;
    .remove {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin: auto;
      background-color: transparent;
      border: none;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 1.7rem;
        line-height: 116.1%;
        letter-spacing: -0.02em;
        color: #1d141f;
        text-align: center;
        border-bottom: 0.1rem solid #9191bd;
      }
    }
  }
}
.promoCode {
  display: flex;
  flex-direction: column;
  margin: 2.4rem 0;
  button {
    background-color: #430d4b !important;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 1rem;
    margin-top: 1rem;
  }

  label {
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3.4rem;
    letter-spacing: -0.02em;
    color: #1d141f;
    opacity: 0.86;
    margin-bottom: 1.8rem;
    width: 100%;
  }
  input {
    width: -webkit-fill-available;
    height: 4.8rem;
    border: 0.1rem solid #cabecd;
    border-radius: 0.8rem;
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.8rem;
    resize: none;
    padding: 1.6rem;
    color: #000000;
    padding: 0 2rem;
  }
}
.profileLeft {
  border: 0.1rem solid #cabecd;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 40rem;
  .imageRadius {
    border: 0.1rem solid rgba(151, 40, 138, 0.2);
    width: 22rem;
    height: 22rem;
    padding: 1rem;
    position: relative;
    border-radius: 50%;
    // overflow: hidden;
    margin-bottom: 3.6rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .imageReload {
      position: absolute;
      right: -1rem;
      top: -1rem;
      background: #ffffff;
      border: 0.12rem solid #e5e5f2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.5rem;
      height: 6.5rem;
      border-radius: 50%;
      z-index: 2;
      svg {
        width: 2.8rem;
        height: 2.8rem;
      }
      input {
        visibility: invisible;
      }
    }
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.8rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: #430d4b;
    display: block;
    margin-bottom: 0.3rem;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1d141f;
    opacity: 0.7;
  }
}
.profileRight {
  border: 0.1rem solid #cabecd;
  border-radius: 2rem;
  padding: 6.2rem 7rem;
  display: flex;
  align-items: flex-start;
  margin-bottom: 2.6rem;
  h2,
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 3.8rem;
    letter-spacing: -0.02em;
    color: #430d4b;
    width: 35%;
  }
  h3 {
    width: 100%;
  }
  form {
    width: 65%;
    margin-right: 6rem;
  }
  &.profileAddress {
    width: -webkit-fill-available;
    margin: 0;
    display: block;
  }
}
.addressAdd {
  border: 0.1rem solid #cabecd;
  border-radius: 0.8rem;
  height: 22rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1.6rem;
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 122.1%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #373676;
  }
}
.addressCard {
  display: block;
  height: 22rem;
  position: relative;
  margin-bottom: 4.6rem;
  cursor: pointer;
  transition: 0.4s;
  .addressCardInner {
    padding: 2rem 1.6rem;
    height: -webkit-fill-available;
    border: 0.1rem solid #cabecd;
    border-radius: 0.8rem;
    position: relative;

    p {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 122.1%;
      color: rgba(55, 54, 118, 0.5);
      margin-bottom: 1.25rem;
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 122.1%;
        letter-spacing: -0.02em;
        color: #373676;
        display: block;
        margin-top: 0.1rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    opacity: 0;
  }

  button {
    position: absolute;
    right: 1.4rem;
    top: 1.4rem;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3rem;
    color: #3c3b9e;
    border: none;
    padding: 0;
    background-color: transparent;
    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.24rem;
    }
  }
  .checkedicon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 105%;
    opacity: 0;
    left: 0;
    right: 0;
    margin: auto;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
  input:checked + .addressCardInner {
    border: 0.1rem solid #15bd74;
  }
  input:checked + .addressCardInner .checkedicon {
    opacity: 1;
  }
}
.purpleButton {
  &.save {
    width: fit-content;
    margin-top: 3.2rem;
    padding: 0 3.2rem;
  }
}
.orderHistory {
  border: 0.1rem solid #e2dee3;
  border-radius: 2rem;
  background: #f6f3f7;
  .accordionInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 3.8rem 0;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 116.1%;
      color: #6e6a6e;
    }
  }
  .MuiCollapse-wrapperInner {
    box-shadow: none !important;
  }
  .MuiButtonBase-root {
    padding: 0 4.8rem;
    box-shadow: none !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;

    background-color: transparent;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .Mui-expanded {
    margin: 0 !important;
  }
  .MuiAccordionDetails-root {
    padding: 0 5.5rem;
  }
  .MuiTypography-root .row {
    overflow: auto;
    height: 40rem;
    padding-right: 2rem;
  }
}
.orderStatus {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 116.1%;
  text-align: center;
  color: #ffffff;
  height: 3.8rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  white-space: nowrap;
  padding: 0 1rem;
  width: 9rem;
  &.paid {
    background: #41ba46;
  }
  &.notPaid {
    background: #1d141f;
  }
}
.orderHistoryTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  border-radius: 2rem;
  padding: 3.8rem 5.8rem 2.4rem;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 116.1%;
    color: #430d4b;
    width: 14%;
    // &:nth-child(2) {
    //   width: 28%;
    // }
  }
}
.orderHistoryCard {
  height: 31.4rem;
  border: 0.1rem solid #cabecd;
  border-radius: 1.2rem;
  margin-bottom: 4rem;
  .orderHistoryInner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .img {
      width: 15rem;
      height: 17.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .orderHostoryBody {
      border-top: 0.1rem solid #cabecd;
      padding: 1rem 2.8rem 2rem;
      width: -webkit-fill-available;
    }
    .orderHistoryCardp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        &:last-child {
          width: 100%;
          div.price {
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 130.1%;
            color: #373676;
          }
        }
      }
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 116.1%;
        color: #373676;
        opacity: 0.5;
        display: block;
        margin-bottom: 0.3rem;
      }
      div.price {
        font-style: normal;
        font-weight: 500;
        font-size: 2.8rem;
        line-height: 116.1%;
        color: #373676;
        display: block;
        margin-bottom: 0;
        width: fit-content;
        svg {
          width: 1.3rem;
          height: 1.1rem;
          margin-left: 0.4rem;
        }
      }
    }
  }
}
.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.83rem;
  background: #f2f2f6;
  height: 3.2rem;
  input {
    width: 5rem;
    height: 100%;
    background-color: transparent;
    border: none;
    text-align: center;
  }
  button {
    background-color: transparent;
    border: none;
    height: 100%;
  }
}
.supportRight {
  padding-left: 3.2rem;
  padding-top: 4.2rem;
}
.supportSide {
  ul {
    padding: 1rem;
    li {
      color: #1d141f;
      font-size: 1.4rem !important;
      font-style: normal;
      font-weight: 400;
      line-height: 116.1%; /* 16.75px */
      letter-spacing: -0.289px;
      list-style-type: none;
      width: 100%;
      opacity: 0.6;
      transition: 0.4s;
      cursor: pointer;
      &:hover {
        transition: 0.4s;
        color: #430d4b;
        opacity: 1;
      }
    }
    .active {
      color: #430d4b;
      font-size: 1.5rem !important;
      font-style: normal;
      font-weight: 500;
      line-height: 116.1%; /* 20.339px */
      letter-spacing: -0.35px;
      opacity: 1;
      list-style-type: disc;
    }
    .active::marker {
      color: #961ea8;
    }
  }
}
.supportContent {
  margin: 2rem 0;
  h2 {
    color: #1d141f;
    font-kerning: none;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: "liga" off;
    font-family: Satoshi;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 127%;
    letter-spacing: 0.28px;
  }
  div {
    color: #1d141f;
    font-kerning: none;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: "liga" off;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; /* 26.35px */
  }
}
.cartEmptyParent {
  padding-top: 2.4rem !important;
  h2 {
    width: 100% !important;
    text-align: center;
  }
  .emptyLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.emptyParent {
  padding: 8.2rem 0 0;

  .emptyLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 3.2rem;
      line-height: 116.1%;
      color: #1d141f;
      margin-bottom: 2rem;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 1.7rem;
      line-height: 116.1%;
      color: #1d141f;
      opacity: 0.5;
      margin-bottom: 1.6rem;
      width: 90%;
    }
    .purpleButton {
      width: fit-content;
      padding: 0 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2.4rem;
    }
  }
  .emptyRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
      width: fit-content;
      object-fit: contain;
      height: 36.2rem;
    }
    img {
      width: fit-content;
      height: 36rem;
      object-fit: contain;
    }
  }
}
.progressBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  border: 0.1rem solid #430d4b;
  border-radius: 0.8rem;
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 122.1%;
    color: #430d4b;
    padding: 2.4rem 3.2rem;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    pointer-events: none;
    white-space: nowrap;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 0.1rem;
      background-color: #430d4b;
      transform: rotate(20deg);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
.page {
  .blogTop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      span {
        color: #1d141f;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.026rem;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        width: fit-content;
        &:first-child {
          border-right: 0.1rem solid rgba(0, 0, 0, 0.3);
          padding-right: 1rem;
        }
        svg {
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.6rem;
        }
      }
    }
  }
}
.progressBarParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.2rem;
  &.cartAddress {
    a {
      &:first-child {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &.cartPayment {
    a {
      opacity: 1;
      pointer-events: all;
    }
  }
}
.cartLeft {
  width: 95%;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 122.1%;
    color: #1d141f;
    margin-bottom: 2.4rem;
  }
  .cartCard {
    border-bottom: 0.1rem solid #e5e0e7;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 1.6rem;
    width: 100%;
    margin-bottom: 1.6rem;
    position: relative;
    .cartCardInner {
      display: flex;
      align-items: center;
      width: 70%;
      .img {
        background: #ffffff;
        border: 0.06rem solid #eae6eb;
        border-radius: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.2rem;
        height: 9.2rem;
        min-width: 9.2rem;
        margin-right: 1.8rem;

        img {
          width: 6.4rem;
          height: 6.4rem;
          object-fit: contain;
        }
      }
      .rating {
        svg {
          width: 0.6em;
          height: 0.6rem;
          margin-right: 0.6rem;
        }
      }
      .price {
        font-style: normal;
        font-weight: 500;
        font-size: 2.8rem;
        line-height: 116.1%;
        color: #1d141f;
        margin-bottom: 0.8rem;
        svg {
          margin-left: 0.2rem;
          width: 1.2rem;
          height: 1.2rem;
          path {
            fill: #1d141f;
          }
        }
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 122.1%;
        letter-spacing: -0.02em;
        color: #1d141f;
        margin-bottom: 0.4rem;
        width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .quantity-picker {
      border: none !important;
      background: #f0f0f1 !important;
      border-radius: 0.8rem !important;
      height: 3.2rem !important;
      width: 9.2rem !important;
      .quantity-modifier {
        background: #f0f0f1 !important;
      }
      .quantity-display {
        width: 3rem !important;
      }
    }
    .trash {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      background-color: transparent;
      padding: 0;
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
      &:hover {
        svg {
          path {
            fill: #373676;
          }
        }
      }
    }
  }
}
.cartLeftLogout {
  width: 60%;
}
.cartRight {
  border: 0.1rem solid #e5e0e7;
  padding: 3.2rem 6.4rem;
  border-radius: 0.8rem;
  position: sticky;
  top: 3.2rem;
  background-color: #efeaf0;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 122.1%;
    width: 100%;
    color: #1d141f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 122.1%;
      color: #1d141f;
      svg {
        width: 1.6rem;
        height: 1.6rem;
        path {
          fill: #1d141f;
        }
      }
    }
    &.Total {
      margin-bottom: 0;
      font-size: 2.6rem;
      span {
        font-size: 3rem;
        color: #961ea8;
        svg {
          width: 2.4rem;
          height: 2.4rem;
          path {
            fill: #961ea8;
          }
        }
      }
    }
  }
  .purpleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
  }
}
.paymentSelect {
  margin: 2rem 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 3.4rem;
    letter-spacing: -0.02em;
    color: #1d141f;
    opacity: 0.86;
    margin-bottom: 1.8rem;
    width: 100%;
  }
  label {
    position: relative;
    display: block;
    cursor: pointer;
    width: 47%;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
      margin-left: auto;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 3.4rem;
      letter-spacing: -0.02em;
      color: #1d141f;
      opacity: 0.86;
      margin-bottom: 0;
      border: 0.1rem solid #f6f3f7;
      background-color: #f6f3f7;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.8rem;
      border-radius: 0.8rem;
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 1.8rem;
      height: 1.8rem;
      opacity: 0;
    }
    input:checked ~ span {
      background-color: rgba(150, 30, 168, 0.073);
    }
  }
}
.popup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  visibility: hidden;
  transform: translateY(-100%);
  transition: 0.4s all ease;
  .closeButton {
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    cursor: pointer;
  }
  .popupLayer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(13, 9, 13, 0.706);
  }
  .popupInner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    .popupContainer {
      background-color: #fff;
      border: 0.1rem solid rgba(150, 30, 168, 0.073);
      width: 60vh;
      max-height: 80vh;
      border-radius: 0.8rem;
      .popupContainerInner {
        padding: 3.2rem;
        position: relative;
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 2rem;
          line-height: 122.1%;
          text-align: left;
          letter-spacing: -0.02em;
          color: #1d141f;
          margin-bottom: 2.4rem;
        }
      }
    }
  }
  &.active {
    visibility: visible;
    transform: translateY(0);
  }
}
.readMore {
  height: 10rem;
  overflow: hidden;
  transition: 0.4s;
  p {
    margin-bottom: 2rem;
  }
  &.active {
    height: 100%;
  }
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 20rem;
    background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    bottom: 0;
    left: 0;
  }
}
.readMoreButton {
  z-index: 2;
  position: absolute;
  bottom: 2;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: -0.02em;
    color: #1d141f;
    transition: 0.4s;

    &:last-child {
      display: none;
    }
  }
  &.active {
    span {
      &:last-child {
        display: block;
      }
      &:first-child {
        display: none;
      }
    }
  }
}
.phoneFooter {
  svg {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1rem;
  }
  a {
    display: flex;
    align-items: center;
  }
}
.wpFooter {
  display: flex;
  flex-direction: column;
}
.closeMenu {
  display: none;
}
.popupComplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .popupComplateLeft {
    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 4.4rem;
      line-height: 6rem;
      letter-spacing: -0.02em;
      color: #ffffff;
      margin-bottom: 1.2rem;
      white-space: nowrap;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.2rem;
      letter-spacing: -0.01em;
      color: #ffffff;
      white-space: nowrap;
    }
  }

  .img {
    img {
      width: 44.3rem;
      height: 36.3rem;
    }
  }
}
.filterButton,
.searchButton,
.searchParent {
  display: none;
}
.addressCard .editAddress {
  right: 8rem;
}
.error {
  background: #f6f3f7;
  width: 99vw;
  // height: 99vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .errorTop {
    padding: 8.8rem 0;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 3rem;
      img {
        width: 23rem;
        height: 6rem;
      }
    }
    .img {
      width: 100%;
      height: 55.8rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.cartAfter {
  display: block;
  .errorTop {
    .errorLeft {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        color: #430d4b;
        font-size: 5.8316rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 1rem;
      }
      p {
        color: #430d4b;
        font-size: 1.8224rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.0364rem;
        margin-bottom: 4rem;
      }
      a {
        width: fit-content;
        padding: 0 3.2rem;
      }
    }
    .img {
      height: 50rem;
    }
  }
}
.authContainerHeader {
  width: 100%;
  h1 {
    font-size: 3rem !important;
  }
}
.headerFixBottom,
.catalogMenu,
.mobileMenu,
.menuButton {
  display: none;
}
.products {
  .emptyParent {
    .emptyLeft {
      align-items: center;
    }
    .emptyRight {
      justify-content: center;
    }
  }
}
@media (max-width: 767.9px) {
  .whatsApp {
    display: none;
  }
  .cardBody {
    img {
      display: none;
    }
  }
  .singleVideo {
    width: 100%;
  }
  .videos {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.25rem;
  }
  .innerBannerSlide {
    height: 10rem;
  }
  .filterInner {
    font-size: 0.8rem;
  }
  .topSlider {
    .imgContainer {
      h1 {
        font-size: 1.6rem;
        left: 5%;
        width: 80%;
        top: 10%;
      }
      .buyNow {
        left: 5%;
        height: 3.2rem;
        font-size: 1.4rem;
        padding: 0 2rem;
      }
    }
  }
  .products {
    .filter__control {
      width: 10rem;
    }
  }
  .headerFixBottom {
    position: fixed;
    left: 0;
    bottom: 1.6rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .productCatalog {
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      letter-spacing: -0.01em;
      color: #ffffff;
      height: 4.5rem;
      background: #961ea8;
      border-radius: 11.3043px;
      padding: 0 3.6rem;
      display: flex;
      align-items: center;
      margin-right: 1rem;
    }
    .mobileCart {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.5rem;
      background: #ffffff;
      border: 0.1rem solid #e0dee1;
      border-radius: 1.2rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0.0099em;
      color: #430d4b;
      padding: 0 3.6rem;
      svg {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0.8rem;
      }
    }
  }
  .menuButton {
    display: block;
    margin-right: 1.3rem;
    svg {
      width: 2.4rem;
      height: 1.6rem;
    }
  }
  .statistics {
    .statsNumber {
      gap: 0;
      flex-direction: column;
      display: block;
      h3 {
        width: auto;
        margin: 2.6rem 0;
      }
      p {
        font-size: 1.4rem;
        margin: 0;
      }
    }
  }
  .menuParent {
    display: flex;
    align-items: center;
  }
  header {
    .HeaderTop,
    .HeaderBottom,
    .searchBar {
      display: none !important;
    }
    .logo {
      width: 13.8rem !important;
      height: 3.4rem !important;
      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .HeaderMiddle {
      padding: 4.8rem 0 1.6rem;
      .container {
        .accountBar {
          display: flex;
          align-items: center;
          a {
            background-color: transparent;
            margin-left: 0.6rem;
            padding: 0;
            width: 3.2rem;
            height: 3.2rem;
            span {
              display: none;
            }
            svg {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .productsParents {
    padding-top: 2.4rem;
  }
  .topSlider {
    height: 18rem;
    .swiper-button-prev,
    .swiper-button-next {
      width: 3.2rem;
      height: 3.2rem;
      top: 16rem;
      &::after {
        width: 1.6rem;
        height: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center;
      }
    }
    .swiper-button-prev {
      right: 5rem;
      left: auto;
    }
  }
  .InfoBar {
    padding: 3.2rem 0;
    .infoBardCard {
      margin-bottom: 1.2rem;
      justify-content: flex-start;
      p {
        font-size: 1.2rem;
        line-height: 135%;
        padding-left: 0.8rem;
      }
      svg {
        width: 4.2rem;
        height: 4.2rem;
        min-width: 4.2rem;
      }
    }
  }
  .allCategory {
    padding: 3.2rem 0 5.2rem;

    .categoryCard {
      p {
        font-size: 1.2rem;
      }
    }
  }
  .titleHead {
    margin-bottom: 2.4rem;
    p {
      font-size: 2.5rem !important;
      line-height: 3.4rem !important;
    }
    a {
      font-size: 1.3rem !important;
      line-height: 1.8rem !important;
      svg {
        width: 2.2rem;
        margin-left: 1.1rem;
      }
    }
  }
  .productSliders {
    padding: 3.2rem 0 0;
    .swiper {
      padding-bottom: 0;
      .swiper-button-prev,
      .swiper-button-next,
      .swiper-pagination {
        display: none;
      }
    }
  }
  .badge {
    left: 1.2rem;
    top: 1rem;
    font-size: 1rem;
    padding: 0.5rem;
  }
  .discount {
    top: 1rem;
    right: 1.2rem;
    font-size: 1rem;
    padding: 0.5rem;
  }
  .serviceSection {
    padding: 2rem 0;
  }
  .serviceCard {
    width: 100%;
    height: 21rem;
    .serviceCardTop {
      height: 18rem;
    }
    span {
      height: 4rem;
      font-size: 1.1rem;
      line-height: 1.4rem;
      padding: 0 1rem;
    }
  }
  .banner {
    padding: 4rem 0;
    .bannerCard {
      height: 13.8rem;
      margin-bottom: 1rem;
    }
  }
  .brandsPageDiv {
    .row {
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;
    }
  }
  .brands {
    .brandsCard {
      margin-bottom: 0.8rem;
      height: 15rem;
    }
  }
  .blogSection {
    padding: 4.8rem 0 6.4rem;
  }
  .blogCard {
    height: 37.8rem;
    .layerContent {
      padding: 3rem 0 0 3rem;
      span {
        font-size: 2.4rem;
        line-height: 116.1%;
      }
      svg {
        width: 2.6rem;
        height: 2rem;
      }
    }
  }
  footer {
    margin-top: 3.2rem;
    padding-bottom: 7rem;
    .footerTop {
      padding: 3.2rem 0;
      .footerTopInner {
        flex-direction: column;
        align-items: flex-start;
        .socials {
          margin-left: 0;
          a {
            margin-left: 0;
            margin-right: 1.2rem;
          }
        }
        .footerTopInnerLeft {
          flex-direction: column;
          align-items: flex-start;
        }
        .footerContactDiv {
          border-right: 0;
          padding-right: 0;
          margin-bottom: 2.4rem;
        }
      }
    }
    .footerMiddle {
      padding: 3.2rem 0 1.6rem;
      .footerDiv {
        ul {
          width: 50%;
          margin-bottom: 2.4rem;
          li {
            margin-bottom: 1.6rem;
            a {
              font-size: 1.3rem;
              line-height: 1.8rem;
            }
            &:first-child {
              margin-bottom: 2rem;
              a {
                font-size: 2rem;
                line-height: 2.7rem;
              }
            }
          }
        }
      }
    }
    .footerBottom {
      padding: 2.4rem 0;
      flex-direction: column;
      align-items: flex-start;
      span {
        margin-bottom: 2.4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        p {
          margin-left: 0 !important;
          margin-top: 1rem;
        }
      }
      p {
        justify-content: center;
        text-align: center;
        width: 100%;
      }
    }
  }
  .seoSection {
    margin: 0 !important;
    padding: 3.2rem 0 6.4rem;
    p {
      font-size: 1.4rem;
    }
  }
  .productLeft {
    position: absolute;
    left: 0;
    top: 0;
    width: -webkit-fill-available;
    height: 90vh;
    background-color: #fff;
    z-index: 4;
    padding: 5.2rem 1.6rem 4.2rem;
    overflow: auto;
    display: none;
    z-index: 14;
    &.active {
      display: block;
    }
  }
  .productsParents {
    .productsTop {
      margin-bottom: 1.8rem;
      margin-top: 1rem;
      flex-wrap: wrap;
      h2 {
        font-size: 2rem;
      }
      .productCount {
        font-size: 1.6rem;
        white-space: nowrap;
      }
    }
    .productLeftTop {
      padding: 0 1.6rem;
      margin-bottom: 1rem;
      p {
        font-size: 1.8rem;
      }
    }
    .filterButton {
      background: #ffffff;
      border: 0.1rem solid #eeecee;
      border-radius: 0.4rem;
      padding: 0 2rem;
      height: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-left: 2.4rem;
      font-style: normal;
      font-weight: 800;
      font-size: 1.6rem;
      line-height: 116.1%;
      letter-spacing: -0.02em;
      color: #961ea8;
      transition: 0.4s;
      &:hover {
        background-color: #430d4b;
        color: #fff;
      }
    }
    .filterCard {
      padding-bottom: 1.6rem;
      margin-bottom: 1.6rem;
      p {
        font-size: 1.6rem;
      }
      p,
      label {
        margin-bottom: 1rem;
      }
    }
  }
  .closeMenu {
    display: block;
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .subProductsTop {
    flex-direction: column;
    align-items: flex-start !important;
    .subProductsLeft {
      margin-bottom: 2.4rem;
    }
  }
  .productDetail {
    .InfoBar {
      margin-top: 0;
    }
    .productDetailTop {
      padding: 2.4rem 0;
      h1 {
        font-size: 2.4rem;
        line-height: 120%;
      }
      .productDetailTopInner {
        justify-content: space-between;
        .rating {
          padding-right: 1rem;
          border-right: 0;
          span {
            font-size: 1.3rem;
          }
        }
        .share {
          padding: 0 1rem;
          border-right: 0;

          span {
            margin: 0 1.6rem;
            display: none;
          }
        }
        .fav {
          padding-left: 1rem;
          span {
            display: none;
          }
          label {
            div {
              width: 4rem;
              height: 4rem;
            }
          }
        }
      }
    }
  }
  .sliderParent {
    margin-bottom: 4rem;
    .mySwiper2 {
      height: 30rem;
    }
  }
  .Collapsible {
    margin-top: 0;
    margin-bottom: 2.4rem;
    .collapseBody {
      padding: 0;
      ul {
        padding-left: 0;
      }
    }
  }
  .productDetailRight {
    padding: 1.6rem;
    .price {
      span {
        font-size: 3rem;
      }
      .discountPrice {
        font-size: 2rem;
      }
    }
    .cardCheckout {
      button.addToCart {
        font-size: 1.2rem;
      }
    }
    .quantity-display {
      font-size: 1.4rem;
      padding: 0;
    }
  }
  .quantity-picker {
    width: 10rem;
  }
  .aboutCard {
    padding: 3.2rem 1rem 2rem;
    .aboutCardInner {
      width: 90%;
    }
  }
  .about {
    h1,
    h2,
    h3 {
      font-size: 2.6rem;
      margin-bottom: 2rem;
      text-align: left;
      line-height: 120%;
    }
    p {
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
    }
  }
  .aboutImg {
    height: 50vh;
    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw !important;
    position: relative;
    left: 50%;
    right: 50%;
    display: block;
    margin-top: -4rem;
    z-index: -1;
    margin-bottom: -4rem;
  }
  .watchButton {
    padding: 0 2rem;
    height: 4rem;
    font-size: 1.4rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
  .aboutBottom {
    padding: 3.4rem 0 4.2rem;
    .aboutBottomInner {
      padding-top: 2.4rem;
      svg {
        width: 3.1rem;
        height: 5rem;
      }
      p {
        font-size: 1.8rem;
      }
    }
    .aboutBottomLeft {
      height: 35rem;
      margin-bottom: 2.4rem;
    }
    .aboutBottomRight {
      span {
        font-size: 1.4rem;
      }
      h3 {
        font-size: 2.6rem;
      }
    }
  }
  .pageTitle {
    h1 {
      font-size: 3.2rem;
      line-height: 120%;
    }
  }
  .page {
    padding-bottom: 4.2rem;
    h2,
    h3,
    h4,
    h5,
    h6 {
      width: 100%;
      font-size: 2rem;
      line-height: 150%;
      margin-bottom: 2rem;
    }
    .pageDetail {
      .share {
        a,
        span {
          margin-bottom: 0;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1.6rem;
        }
      }
      p,
      a,
      span,
      strong {
        font-size: 1.4rem;
      }
      p {
        width: 100%;
        margin-bottom: 3.2rem;
      }
      .pageDetailTitle {
        margin: 3.2rem 0 2.4rem;
        padding-bottom: 1rem;
        h1 {
          font-size: 2.4rem;
          line-height: 120%;
        }
      }
    }
    ul {
      li {
        width: 100%;
        font-size: 1.4rem;
        line-height: 130%;
      }
    }
  }
  .videoGallery {
    height: 30rem;
  }
  .auth {
    .img {
      display: none;
    }
    .authContainerParent {
      padding: 3.2rem 0;

      .authContainer {
        .authContainerHeader {
          h1 {
            font-size: 3.6rem;
            margin-bottom: 1rem;
            line-height: 140%;
          }
        }
      }
    }
  }
  .purpleButton,
  .buttons a {
    font-size: 1.6rem;
    height: 4rem;
  }
  .numberSelect .filter__control {
    width: 7rem;
  }
  .input-child {
    margin-bottom: 1.6rem;
  }
  .profile {
    .pageTitle {
      margin: 2.4rem 0;
      .profilePic {
        width: 4.2rem;
        height: 4.2rem;
        margin-right: 1rem;
      }
      h1 {
        font-size: 2.4rem;
      }
    }
  }
  .profileCard {
    margin-bottom: 2.4rem;
    height: 25rem;
    .profileCardInner {
      padding: 2.4rem;
      svg {
        width: 12rem;
        height: 12rem;
        object-fit: contain;
      }
    }
  }
  .fav {
    .pageTitle {
      .svg {
        margin-right: 1rem;
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
  .profileLeft {
    height: 30rem;
    margin-bottom: 2.4rem;
    .imageRadius {
      width: 12rem;
      height: 12rem;
      padding: 0.5rem;
      margin-bottom: 2rem;
      .imageReload {
        width: 4.2rem;
        height: 4.2rem;
        cursor: pointer;
        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
    span {
      font-size: 2rem;
      line-height: 120%;
    }
    p {
      font-size: 1.4rem;
    }
  }
  .profileRight {
    padding: 2rem;
    flex-direction: column;
    h2 {
      br {
        display: none;
      }
    }
    form {
      width: 100%;
    }
  }
  .addressAdd,
  .addressCard {
    margin-bottom: 4.2rem;
  }
  .popup {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 5;
    visibility: hidden;
    transform: translateY(-100%);
    transition: 0.4s all ease;
    .closeButton {
      position: absolute;
      right: 1.2rem;
      top: 1.2rem;
      cursor: pointer;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .popupInner {
      .popupContainer {
        background-color: #fff;
        border: 0.1rem solid rgba(150, 30, 168, 0.073);
        width: 50vh;

        .popupContainerInner {
          padding: 2.4rem;

          p {
            font-size: 1.6rem;
          }
        }
      }
    }
    &.active {
      visibility: visible;
      transform: translateY(0);
    }
    &.deactive {
      visibility: invisible;
    }
  }
  .PageTop {
    .purpleButton {
      padding: 0 1rem;
    }
  }
  .fav {
    .productCardParent {
      padding-bottom: 0;
      margin-bottom: 2rem;
      .productCard {
        margin-bottom: 1rem;
        .remove {
          span {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  .productCard {
    .productCardTop {
      height: 16rem;
      .img {
        min-height: 14rem;
      }
      .rating {
        margin-top: 1rem;
        svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
    .productCardBody {
      padding: 0 1.6rem 1.6rem;
      p {
        font-size: 1.2rem;
        margin-bottom: 1rem;
        height: 3.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .price {
        justify-content: flex-start;
        strong {
          font-size: 1.6rem;
          margin-right: 1rem;
          svg {
            width: 1.2rem;
            height: 1rem;
          }
        }
        span {
          font-size: 1.2rem;
          svg {
            width: 1.2rem;
            height: 1rem;
          }
        }
      }
    }

    .productCardBottom {
      margin-top: 1rem;
      .addToCart {
        margin-right: 0.6rem;
        width: 50%;
        height: 3.8rem;

        span {
          display: none;
        }
        svg {
          margin-right: 0;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      .likeButton {
        width: 50%;
        height: 3.8rem;
        svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }

  .emptyParent {
    padding: 4.2rem 0 0;
    .emptyLeft {
      h2 {
        font-size: 2.4rem;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.4rem;
      }
      .purpleButton {
        width: 100%;
        padding: 0;
      }
    }
    .emptyRight {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      svg {
        height: 20rem;
      }
    }
  }

  .orderHistory {
    overflow: auto;
    width: 100%;
    // .MuiAccordionSummary-content {
    //   overflow: hidden;
    // }
    .MuiPaper-root {
      width: fit-content;
    }
    .MuiCollapse-root {
      width: 90vw;
      position: sticky;
      left: 0;
    }
    .MuiAccordionDetails-root {
      padding: 0 2rem;
    }
    .orderHistoryTop {
      padding: 2rem;
      width: fit-content;
      span {
        font-size: 1.2rem;
        margin-right: 2rem;
        white-space: nowrap;
        min-width: 9rem;
      }
    }
    .MuiButtonBase-root {
      padding: 0 2rem;
    }
    .accordionInner {
      padding: 1rem 0;

      p {
        font-size: 1rem;
        white-space: nowrap;
        min-width: 8rem;
        margin-right: 3rem;
      }
    }
  }

  .orderStatus {
    font-size: 1rem;
    height: 3.2rem;
    width: 6rem;
  }
  .storeSide {
    padding: 2.4rem;
    a {
      margin-bottom: 1rem;
      &.active {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        padding-left: 2rem;
      }
    }
  }
  .supportRight {
    padding-left: 0;
  }
  .contactTop {
    flex-direction: column;
    .contactLink {
      padding: 0;
      width: 100%;
      margin: 0 0 1.6rem;
      height: 4.8rem;
      font-size: 1.6rem;
      svg {
        width: 2rem;
        height: 2rem;
        margin-left: 2rem;
      }
    }
  }
  .contactIframe {
    margin-bottom: 4rem;
    iframe {
      height: 30rem;
    }
  }
  .contactForm {
    span {
      font-size: 3.2rem;
      line-height: 120%;
    }
    p {
      margin-bottom: 2.4rem;
    }
  }
  .inputGroup {
    flex-direction: column;
    &:last-child {
      .input-child {
        &:last-child {
          order: -1;
        }
      }
    }
    button {
      width: 100%;
    }
  }
  .storeRight {
    margin-top: 3.2rem;
    padding-left: 0;
    .storeDetail {
      flex-direction: column;
      align-items: flex-start;
      p {
        width: 100%;
        margin-bottom: 1rem;
        font-size: 1.4rem;
      }
    }
    .aboutImg {
      height: 30rem;
      .playButton {
        width: 8rem;
        height: 8rem;
        svg {
          width: 4rem;
          height: 4rem;
        }
      }
    }
    iframe {
      height: 30rem;
    }
  }
  .progressBarParent {
    flex-direction: column;
    align-items: flex-start;
    .progressBar {
      width: -webkit-fill-available;
      a {
        padding: 1.6rem 0;
        font-size: 1.8rem;
      }
    }
  }
  .cartRight {
    padding: 2.4rem;
  }
  .cartLeftLogout {
    width: 100%;
  }
  .mobileInnerTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .mobileMenu {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #f6f3f7;
    z-index: 12;
    overflow: auto;
    transform: translateX(-110%);
    transition: 0.4s all ease;
    &.active {
      transform: translateX(0);
    }
    .mobileInner {
      padding: 4.8rem 1.6rem 2rem;
      overflow: hidden;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .menuList {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      a {
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 144%;
        text-align: center;
        letter-spacing: -0.0008em;
        color: #1d141f;
        margin-bottom: 1.2rem;
        &:hover {
          color: #55175e;
        }
      }
    }
    .menuBottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a.callNumber {
        margin-bottom: 3.2rem;
        font-size: 1.5rem;
        line-height: 2rem;
      }
      .socials {
        margin-left: 0;
        a {
          width: 4.4rem;
          height: 4.4rem;
        }
      }
    }
  }
  .searchButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.6rem;
    width: 3.2rem;
    height: 3.2rem;
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
  .searchParent {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f6f3f7;
    z-index: 12;
    transform: translateX(-120%);
    transition: 0.4s all ease;
    &.active {
      transform: translateX(0);
    }
    .searchMenu {
      padding: 4.8rem 1.6rem 2rem;
      .mobileInnerTop {
        margin-bottom: 2.2rem;
      }
    }
    form {
      border: 0.1rem solid #cabecd;
      border-radius: 0.8rem;
      width: 100%;
      position: relative;
      height: 4rem;
      display: flex;
      align-items: center;
      input {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: -0.0008em;
        color: #1d141f;
        opacity: 0.5;
        height: 100%;
        border: none;
        padding-left: 4rem;
        width: -webkit-fill-available;
        background-color: transparent;
      }
      button {
        position: absolute;
        left: 0;
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        border: none;
        background-color: transparent;
        padding-left: 1rem;
        svg {
          width: 2.1rem;
          border: none;
          background-color: transparent;
          height: 2.1rem;
        }
      }
    }
  }
  .mobileNone {
    display: none !important;
  }
  .tags {
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
    width: 100%;
    overflow: auto;
    margin-bottom: 2rem;
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.8rem;
      letter-spacing: -0.0008em;
      color: #1d141f;
      margin-right: 2.2rem;
    }
  }
  .searchCard {
    margin-bottom: 0;
  }
  .moreButton {
    background-color: transparent;
  }
  .catalogMenu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f6f3f7;
    z-index: 12;
    transform: translateX(-120%);
    transition: 0.4s all ease;
    &.active {
      transform: translateX(0);
    }
    .catalogMenuInner {
      padding: 4.8rem 1.6rem 2rem;
      position: relative;
      height: -webkit-fill-available;
      .closeCatalog {
        position: absolute;
        top: 10rem;
        z-index: 3;
        left: 0;
        background-color: transparent;
        width: 3rem;
        height: 3rem;
      }
    }
    .mobileInnerTop {
      padding-bottom: 3.2rem;
    }
    .catalogMenuList {
      a.titleCatalog {
        font-style: normal;
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.8rem;
        letter-spacing: -0.01em;
        color: #1d141f;
        margin-bottom: 3.6rem;
      }
      .catalogLisItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.2rem;
        letter-spacing: -0.01em;
        color: #000000;
        margin-bottom: 2rem;

        svg {
          width: 0.6rem;
          height: 1.2rem;
        }
        .CatalogListChild {
          position: absolute;
          top: 10rem;
          left: 0;
          width: -webkit-fill-available;
          height: -webkit-fill-available;
          z-index: 2;
          background: #f6f3f7;
          padding: 0 1.6rem;
          transform: translateX(-120%);
          transition: 0.4s all ease;

          .titleCatalogChild {
            border-bottom: 0.1rem solid #e6e8ed;
            padding-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 3.2rem;
            svg {
              transform: rotate(-180deg);
              width: 0.6rem;
              height: 1.4rem;
              margin-right: 1.2rem;
            }
            a {
              font-style: normal;
              font-weight: 700;
              font-size: 2rem;
              line-height: 3rem;
              letter-spacing: -0.01em;
              color: #1d141f;
            }
          }
          ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 75vh;
            li {
              margin-bottom: 2.4rem;
              width: 40%;
              a {
                font-style: normal;
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1.6rem;
                letter-spacing: 0.0099em;
                color: #1c1c1c;
                opacity: 0.76;
                margin-bottom: 0;
                padding: 0;
                &:hover {
                  opacity: 1;
                  color: #430d4b;
                  transform: translateX(1rem);
                }
              }
            }
          }
        }
        &:hover {
          .CatalogListChild {
            transform: translateX(0);
          }
        }
      }
    }
  }
  .cartAfter {
    .errorTop {
      padding: 3.2rem 0;

      .errorLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 2.8rem;
        }
        p {
          font-size: 1.6rem;
          text-align: center;
          margin-bottom: 2rem;
        }
      }
      .img {
        height: 30rem;
      }
    }
  }
}
